import React, {useState, useEffect} from 'react';
import parse from 'html-react-parser';
import { Link, useHistory } from 'react-router-dom';
import { IMC, WPAPI } from '../services/wpClient';
import {scrollUp} from '../services/helpers.js';
import Nav from '../components/nav';
import {ReactComponent as ClearIcon} from '../img/clear.svg';
import LoadingIcon from '../components/LoadingIcon';
import "./SearchPage.scss";


function SearchPage({match}){
	
	const [results, setResults] = useState([]);
	const [total,setTotal] = useState([]);
	const [isSearching, setSearching] = useState(true);
	const [isTouched, setTouched] = useState(false);
	const [searchPageQuery, setSearchPageQuery] = useState(() => {return( typeof match !== "undefined" ? match.params.query : "" )});
	
	let history = useHistory();
	
	
	const handleClearSearch = (e) => {
		
		e.preventDefault();
		
		document.getElementById('search').value = "";
		
		setSearchPageQuery("");
		
		history.replace('/search/');
		
		setTouched(false);
		
	}
	
	
	
	const fetchResults = async () => {
		
		try {
			
			// console.log('searching...');
			
			setSearching(true);
			
			if (typeof searchPageQuery == "undefined"){
				
				setResults([]);
				setTotal(0);
				
				return null;
			}
			
			const searchUrl = new URL(IMC.content+WPAPI.searchUrl+searchPageQuery);
			const response = await fetch(searchUrl);
			const data = await response.json();
			const items = data.items;
			const total = data.total;
			
			setResults(items);
			setTotal(total);
			
		} catch (error) {
			
			console.log("There was an error: ",error);
			
		} finally {
			
			console.log('done searching!');
			
			
			
			setSearching(false);
			
		}	
		
	};
	
	const handleSubmit = (e) => {
		
		e.preventDefault();
		// eslint-disable-next-line
		const searchValue = document.getElementById('search').value.replace(/[.,\/#!?$%\^&\*;:{}=\-_`~()]/g,"");
		
		setSearchPageQuery(searchValue);
		
		history.replace('/search/'+searchValue);
		
	}
	
	
	const handleInputChange = (e) => {
		
		const searchValue = document.getElementById('search').value;
		
		const newTouchedState = searchValue ? true : false;
		
		setTouched(newTouchedState);
		
	}
	
	useEffect(() => {
		
		// console.log(searchPageQuery);
		
		fetchResults();
		
		scrollUp();
		// eslint-disable-next-line
	}, [searchPageQuery]);
	
	return (
		<div className="page search-results">
			<header className="page-header">
				<div className="search-form-wrapper">
					<form className="search-form theme-dark" onSubmit={handleSubmit}>
						<div className="searchbox-container">
							<div className="field">
								<input type="text" placeholder={"Search"} id="search" name="search" onChange={handleInputChange} defaultValue={searchPageQuery} />
								{ isTouched && (
									<button onClick={handleClearSearch} className="clear-search" type="button"><ClearIcon /></button>
								) }
								<button className="button cta invert" onClick={handleSubmit}>Search</button>
							</div>
						</div>
					</form>
					{
						!total && !isSearching ? (
							<div className="menu-wrapper">
								<h3 className="uppercase">Go to</h3>
								<Nav id="main-nav" menuID="main-menu" isMenuOpen={false} setMenuOpen={()=>{}} />
								<Nav id="secondary-nav" menuID="secondary-menu" isMenuOpen={false} setMenuOpen={()=>{}} />
							</div>
						) : ( null )
					}
				</div>
			</header>
			{
				isSearching ? (
					<div className="page-content">
						<LoadingIcon />
					</div>
				) : (
					
					<div className="page-content">
					{
						total ? (
							<>
							<h1 className="title__xs uppercase search-results-title">Search Results</h1>
							<ul className="results-list">
							{
								results.map((result) => {
									const resultLink = "/"+result.archive+"/"+result.slug;
									return (
										<li className="search-result">
											<div className="item-content flex">
												{ result.thumb && <div className="item-image"><Link to={resultLink}><img src={result.thumb} alt=""/></Link></div> }
												<div className="item-meta">
													<h4 className="title__subtitle item-type">{result.archive}</h4>
													<h2 className="title__primary item-title"><Link to={resultLink}>{parse(result.title)}</Link></h2>
													<div className="item-excerpt">{ result.excerpt.split('\n').map((line)=> <p>{parse(line)}</p>)	}</div>
												</div>
											</div>
										</li>
									)
								})
							}
							</ul>
							</>
						) : ( null )
					}
					{
						searchPageQuery && !total ? (
							<div className="no-results">
								<p>No results for "{searchPageQuery}"</p>
							</div>
						) : (
							null
						)
					}
					</div>
					
				)
				
			}

		</div>
	);
	
}

export default SearchPage
