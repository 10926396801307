import React from 'react';
import BlockParser from '../../components/BlockParser';

function WpBlockUagbSection(props) {
	
	const block = props.block;
	const bgImage = block.attrs.backgroundImage ? block.attrs.backgroundImage.url : null;
	const sectionContentClass = bgImage ? "section-content section-has-background-img" : "section-content";
	const sectionContentStyle = bgImage ? {background: `url("${bgImage}") no-repeat center center`, backgroundSize: "cover"} : {}
	
	return (
		<section id={`wp-block-${block.attrs.block_id}`} key={block.attrs.block_id} className={`wp-block ${block.attrs.className ? block.attrs.className : ""}`}>
			<div className={sectionContentClass} style={sectionContentStyle}>
				{
					block.innerBlocks && (
						block.innerBlocks.map((innerBlock,blockIdx)=>{
							return(<BlockParser key={blockIdx} block={innerBlock} />)
						})
					)
				}
			</div>
		</section>
	);
	
}

export default WpBlockUagbSection;
