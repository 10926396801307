import React, {useState, useEffect} from 'react';
import { IMC, WPAPI } from '../services/wpClient';
import COUNTRIES from '../services/countries';
import {Link} from 'react-router-dom';
import parse from 'html-react-parser';
import Slider from "react-slick";
import LoadingIcon from '../components/LoadingIcon';
import "./carousel.scss";
import {ReactComponent as ReloadIcon} from '../img/reload.svg';

//import Carousel from 'react-gallery-carousel';
//import 'react-gallery-carousel/dist/index.css';


let clickTimeout = null;
let disableClick = false;

function resumeClick(e){
	setTimeout(()=>{ disableClick = false; },100);
	clearTimeout(clickTimeout);
}

function pauseClick(e){			
	clickTimeout = setTimeout(()=>{ disableClick = true; },200);
}

function handleClick(e){
	if (disableClick === true){ e.preventDefault(); }
}


function CptCarousel(props){
	
	const [isLoading,setLoading] = useState(true);
	const [slides,setSlides] = useState([]);
	
	//const slides = props.slides;
	const [carouselProps,setCarouselProps] = useState(props.carouselProps);
	
	const settings = {
		dots: carouselProps.dots,
		arrows: carouselProps.arrows,
		infinite: true,
		speed: 500,
		slidesToShow: carouselProps.ipsDesktop,
		slidesToScroll: carouselProps.ipsDesktop,
		// adaptiveHeight: true,
		responsive: [
			{
				breakpoint: 1023,
				settings: {
					slidesToShow: carouselProps.ipsTablet,
					slidesToScroll: carouselProps.ipsTablet,
					dots: true,
					arrows: false,
				}
			},
			{
				breakpoint: 767,
				settings: {
					slidesToShow: carouselProps.ipsMobile,
					slidesToScroll: carouselProps.ipsMobile,
					dots: true,
					arrows: false,
				}
			}
		]
	};
	
	const fetchPosts = async (postType) => {
		
		setLoading(true);
		
		let tempSlides = [];
		
		const postsURL = new URL(IMC.content+WPAPI[postType]);
		
		const urlParams = {
			s: "",
			maxposts: carouselProps.postsN,
			offset: 0,
			rand: 1,
		};
		
		postsURL.search = new URLSearchParams(urlParams);
		
		const response = await fetch(postsURL);
		
		const data = await response.json();
		
		const items = data.items;

		items.map((item) => {
			
			let itemHTML;
			
			switch (postType){
				
				case "artworks":
					itemHTML = (
						<Link to={`/${postType}/${item.slug}/`} className="slide-content" onMouseDown={pauseClick} onMouseUp={resumeClick} onClick={handleClick}>
							<div className="image"><img src={IMC.media+"artworks/"+item.thumb} alt="" /></div>
							<div className="post-info">
								<span className="title__m artwork-artist">{item.artist}</span>
								<span className="title__s artwork-title">{parse(item.title)}</span>
								<span className="title__xxs weight-400 uppercase grey artwork-region">{item.year}, {COUNTRIES.name[item.region]}</span>
							</div>
						</Link>
					);
					break;
				
				case "catalogues":
					itemHTML = (
						<Link to={`/${postType}/${item.slug}/`} className="slide-content" onMouseDown={pauseClick} onMouseUp={resumeClick} onClick={handleClick}>
							<div className="image"><img src={item.thumb} alt="" /></div>
							<div className="post-info">
								<span className="title__xxs uppercase catalogue-region">{COUNTRIES.name[item.region]}</span>
								<span className="title__m catalogue-name">{item.title}</span>
								<span className="title__xxs title__xs weight-400 uppercase grey catalogue-artworks-count">{item.artworksCount} artworks</span>
							</div>
						</Link>
					);
					break;
				default:
					itemHTML = (
						<Link to={`/${postType}/${item.slug}/`} className="slide-content" onMouseDown={pauseClick} onMouseUp={resumeClick} onClick={handleClick}>
							{ item.thumb && 
								<img src={IMC.media+item.thumb} alt="" />
							}
							<h3>{item.title}</h3>
						</Link>
					);
					break;
			}
			
			tempSlides.push({content: itemHTML });
			
			return true;
			
		});

	
		setSlides(tempSlides);
		
		setLoading(false);
		
	}
	
	useEffect(() => {
		
		
		setCarouselProps(props.carouselProps);
		
		fetchPosts(props.carouselProps.postType);
		
		// setSlides(props.slides);
		// eslint-disable-next-line
	},[]);
	
	return(
				<section className="carousel-container">
					<header className="section-header">
						<h3 className="title__xl section-title">{carouselProps.title}</h3>
						{ carouselProps.archiveLink && (
							<Link to={props.carouselProps.archiveLink.url} className="button underline">
								{props.carouselProps.archiveLink.title}
							</Link>
						)}
					</header>
				<>
		{
			isLoading ? <LoadingIcon className="mini" /> : (
				<>
					<Slider {...settings} className={`slider-post-type-${carouselProps.postType} desktop-cols-${carouselProps.ipsDesktop} tablet-cols-${carouselProps.ipsTablet} mobile-cols-${carouselProps.ipsMobile}`}>
						{
							slides.map((slide,l) => {
								return (
									<div className="slide" key={l}>{slide.content}</div>
								)
							})
						}
					</Slider>
					{ !!parseInt(carouselProps.reloadBtn) === true && (
						<footer>
							<button className="cta reload" onClick={ () => fetchPosts(carouselProps.postType) }><ReloadIcon /><span>Reload {carouselProps.postType}</span></button>
						</footer>
					)}
					</>
			)
		}
			</>
				</section>
	);
}

export default CptCarousel;
