import React from 'react';
import parse from 'html-react-parser';
import WpBlockFeaturedArtwork from '../components/wp-blocks/featuredArtwork';
import WpBlockMapsProjects from '../components/wp-blocks/MapsProjects';
import WpBlockCarousel from '../components/wp-blocks/carousel';
import WpBlockCover from '../components/wp-blocks/Cover';
import WpBlockMediaText from '../components/wp-blocks/MediaText';
import WpBlockUagbSection from '../components/wp-blocks/UagbSection';
// import CustomContentModal from '../components/CustomContentModal';
// import WPAPI from '../services/wpClient';

const BlockParser = (props) => {
	
	const block = props.block;
	
	switch(block.blockName){
		case "core/media-text":
			return(<WpBlockMediaText block={block} />);
			
		case "core/cover":
			return(<WpBlockCover block={block} />)
			
		case "uagb/section":
			return(<WpBlockUagbSection block={block} />)
			
		case "acf/featured-artwork":
			return(<WpBlockFeaturedArtwork block={block} />)
			
		case "acf/posts-carousel":
		case "acf/content-carousel":
			return(<WpBlockCarousel block={block} />)
			
		case "acf/posts-grid":
			return( <WpBlockMapsProjects block={block} /> )
			
		default:

			if (block.innerBlocks.length){
				
				// console.log('yoooooooooooo',block);
				
				let parentTag = parse(block.innerContent[0].replace("\n",""));
				let parentClasses = parentTag.props.className;
				let theTagName = parentTag.type;
				
				// let hasChildren = parentTag.props.children;
				
				// console.log(parentTag,hasChildren);
				
				let children = [];
				
				block.innerBlocks.map((innerBlock,blockIdx)=>{
					// console.log(innerBlock);
					children.push(<BlockParser key={blockIdx} block={innerBlock} />)
					return true;
				});
				
				const theTag = React.createElement( theTagName, {className: parentClasses}, children);
				
				return(
					<>{theTag}</>
				)
				
			} else {
				// console.log('fired default block!', block.blockName);
				return parse(block.innerHTML);
			}
			
	}	
	
}

export default BlockParser;
