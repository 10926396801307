import React, {useState, useEffect} from 'react';
import {NavLink} from 'react-router-dom';
import parse from 'html-react-parser';
import { IMC, WPAPI } from '../services/wpClient';

function Nav(props){
	
	const [menuItems,setMenuItems] = useState([]);
	// eslint-disable-next-line
	const [isMenuOpen,setMenuOpen] = [props.isMenuOpen, props.setMenuOpen];
	
	const menuID = props.menuID;
	
	useEffect(() => {
		
		const fetchMenu = async () => {
			
			if (sessionStorage.getItem("imc-menu-"+menuID)){
				
				const data = JSON.parse(sessionStorage.getItem("imc-menu-"+menuID));
				
				setMenuItems(data);
				
			} else {
				
				const fetchMenuItems = await fetch(IMC.content+WPAPI.menu+menuID);
				
				const data = await fetchMenuItems.json();
				
				sessionStorage.setItem("imc-menu-"+menuID,JSON.stringify(data));
				
				setMenuItems(data);
				//console.log(data);
				
			}
			
			
		}
		
		fetchMenu();
		// eslint-disable-next-line
	},[]);
	
	const menuClass = "nav-menu "+props.className;
	
	return(
		<nav id={props.id}>
			<ul className={menuClass}>
				{menuItems.map((menuItem) => {
					
					const children = menuItem.children;
					
					const itemClasses = menuItem.classes ? " "+menuItem.classes.join(" ") : "";
					
					const classList = children.length > 0 ? "menu-item has-children"+itemClasses : "menu-item"+itemClasses;
					
					const menuItemLinkElement = (menuItem) => {
						menuItem.title = parse(menuItem.title);
						if (menuItem.url){
							return(
								<a href={menuItem.url} onClick={() => { setMenuOpen(false)} } target={menuItem.target || undefined} rel={ menuItem.target === "_blank" ? "noreferrer" : undefined }>{menuItem.title}</a>
							)
						} else {
							return (
								<NavLink activeClassName="current" exact to={"/"+menuItem.slug} onClick={() => { setMenuOpen(false)} } target={ menuItem.target || undefined} rel={ menuItem.target === "_blank" ? "noreferrer" : undefined }>{menuItem.title}</NavLink>
							)
						}
					}
					
					let childrenHTML;
					
					if (children.length > 0) {
						childrenHTML = <ul className="nav-menu sub-menu">
							{children.map((childItem)=>{
								
								if (childItem.url){
									childItem.title = parse(childItem.title)
									return(
										<a href={childItem.url} onClick={() => { setMenuOpen(false)} } target={childItem.target} rel={ childItem.target === "_blank" ? "noreferrer" : undefined }>{childItem.title}</a>
									)
								} else {
									return (
										<NavLink activeClassName="current" exact to={"/"+childItem.slug} onClick={() => { setMenuOpen(false)}}>{childItem.title}</NavLink>
									)
								}
								
							})}
						</ul>;
					}
					
					
					
					return (
						<li key={menuItem.id} id={`menu-item-${menuItem.id}`} className={classList}>{menuItemLinkElement(menuItem)}{childrenHTML}</li>
					)
				}
				)}
			</ul>
		</nav>
	);
}

export default Nav;
