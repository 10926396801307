import './LoadingIcon.scss';
import {ReactComponent as IconLoading} from '../img/loading-squares.svg';

function LoadingIcon(props){
	
	const className = props.className ? "loading-icon "+props.className : "loading-icon";
	
	return(
		<div className={className}><IconLoading /></div>
	);
}

export default LoadingIcon;
