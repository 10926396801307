import {ReactComponent as IconFacebook} from '../img/icon-facebook.svg';
import {ReactComponent as IconInstagram} from '../img/icon-instagram.svg';
import "./UI-SocialButtons.scss";

function UISocialButtons(props){
	
	const mode = props.mode ? props.mode : "text";
	
	const links = [
		{
			id: 0,
			url: "https://www.facebook.com/FondazioneImagoMundi/",
			label: "Facebook",
			icon: <IconFacebook />
		},
		{
			id: 1,
			url: "https://www.instagram.com/fondazioneimagomundi/",
			label: "Instagram",
			icon: <IconInstagram />
		},
	];
	
	return(
		<ul className={"social-buttons "+mode}>
		{
			links.map((link) => {
				let linkHTML;
	
				if (mode === "icons"){
					linkHTML = link.icon;
				} else if (mode === "text"){
					linkHTML = link.label;
				}
					
					return (
					<li key={link.id}><a href={link.url} target="_blank" rel="noreferrer">{linkHTML}</a></li>
					)
			})
			
		}</ul>
	);
}

export default UISocialButtons;
