import React from 'react';
import parse from 'html-react-parser';
import BlockParser from '../../components/BlockParser';

function WpBlockMediaText(props) {
	
	const block = props.block;
	const innerBlocks = block.innerBlocks;
		
	const parentBlock = parse(block.innerContent[0].replace("\n",""));
	const parentClasses = parentBlock.props.className;
	
	const children = parentBlock.props.children;
	
	const media = children[0];
	const text = children[1];
	
	return (
		<div className={parentClasses}>
			{media}
			<div className={text.props.className}>
				{
					innerBlocks.map( (innerBlock,idx)=> {
						return( <BlockParser key={idx} block={innerBlock} />)
					})
				}
			</div>
		</div>
	);
	
	
}

export default WpBlockMediaText;
