import React, {useState, useEffect} from 'react';
import { IMC, WPAPI } from '../../services/wpClient';
import CustomContentModal from '../../components/CustomContentModal';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';


function WpBlockFeaturedArtwork(props) {
	
	const [isModalOpen, setModalOpen] = useState(false);
	const [featuredArtwork, setFeaturedArtwork] = useState([]);
	
	// let theModal = document.querySelector('.custom-content-modal');
	
	// isModalOpen ? disableBodyScroll(theModal) : enableBodyScroll(theModal);
	
	const featuredArtworkID = props.block.attrs.data.featured_entry;


	useEffect(() => {
		
		const fetchFeaturedArtwork = async () => {
			
			const postsURL = new URL(IMC.content+WPAPI.mapsProjects);
			
			const urlParams = {
				id: featuredArtworkID,
				maxposts: 1,
				offset: 0,
				rand: 0,
			};
			
			postsURL.search = new URLSearchParams(urlParams);
			
			const response = await fetch(postsURL);
			
			const data = await response.json();
			
			if (data.items){
				
				setFeaturedArtwork(data.items[0]);
				
			}
			
	
		}
		
		fetchFeaturedArtwork();
		
		let theModal = document.querySelector('.custom-content-modal');
		
		if (theModal){
			isModalOpen ? disableBodyScroll(theModal) : enableBodyScroll(theModal);
		}
		// eslint-disable-next-line
	},[]);
	
	const blockContent = {
		title: featuredArtwork.sectionTitle,
		bgImage: featuredArtwork.bgImage,
		btnText: featuredArtwork.btnText
	};
	
	const blockContentStyle = {
		backgroundImage: 'url(' + blockContent.bgImage + ')',
		backgroundSize: 'cover',
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'center center'
	};
	
	const textColor = featuredArtwork.textColor ? featuredArtwork.textColor : getComputedStyle(document.documentElement).getPropertyValue('--cta-hero-textColor');
	const textColorHover = featuredArtwork.textColorHover ? featuredArtwork.textColorHover : getComputedStyle(document.documentElement).getPropertyValue('--cta-hero-textColor--hover');
	
	const modalContent = {
		gallery: featuredArtwork.gallery,
		content: featuredArtwork.content
	};
	
	document.documentElement.style.setProperty('--cta-hero-textColor', textColor);
	document.documentElement.style.setProperty('--cta-hero-textColor--hover', textColorHover);
	
	return (
		<section className="hero" style={blockContentStyle}>
		<h2 className="title__xl hero-title">{blockContent.title}</h2>
		<button className="cta modal-toggle" onClick={() => setModalOpen(true)}>{blockContent.btnText}</button>
		<CustomContentModal modalContent={modalContent} isModalOpen={isModalOpen} setModalOpen={setModalOpen}  />
		</section>
	);
	
}

export default WpBlockFeaturedArtwork;
