import React, {useState, useEffect} from 'react';
import {scrollUp} from "../services/helpers.js";
import Nav from '../components/nav';
import UISocialButtons from '../components/UI-SocialButtons';
import "./footer.scss";
import logoSquare from "../img/IMC-logo-square.svg";
import arrowUp from "../img/arrow-up.svg";
	
function Footer(props){
	
	const [isMenuOpen,setMenuOpen] = useState(false);
	
	useEffect(() => {
	},[]);
	
	return(
		<footer id={props.id} className="page-footer">
			<div className="flex fullwidth footer-nav-wrapper">
				<Nav id="footer-nav" menuID="footer-menu" isMenuOpen={isMenuOpen} setMenuOpen={setMenuOpen} />
				<button id="scroll-up" className="noborder" onClick={scrollUp}><img src={arrowUp} alt="" className="ui-nav-icon" /></button>
			</div>
			<div className="grid grid-8col footer-contact-info">
				<div className="grid-col-2fr logo"><img src={logoSquare} alt="Imago Mundi logo - square" /></div>
				<div className="grid-col-2fr name">
					<h3>Imago Mundi Collection</h3>
				</div>
				<div className="grid-col-2fr address">
					<p>Piazza del Duomo, 20<br/>
					31100 Treviso, Italy
					</p>
				</div>
				<div className="grid-col-2fr cf">
					<p className="small-text">Cod. Fisc. 001234567895</p>
				</div>
				<div className="grid-col-2fr contacts">
					<p><a href="tel:+390422512200">+39 0422 512200</a><br/>
					<a href="mailto:info@fondazioneimagomundi.org">info@fondazioneimagomundi.org</a></p>
				</div>
				<div className="grid-col-3fr copyright">
					<p className="small-text" style={{marginBottom:0}}>&copy;{new Date().getFullYear()} Imago Mundi Collection</p>
					<Nav id="terms-nav" menuID="terms-menu" className="terms-menu" isMenuOpen={isMenuOpen} setMenuOpen={setMenuOpen} />
				</div>
				<div className="grid-col-1fr follow-us">
					<p>Follow us:</p>
					<UISocialButtons mode="text" />
				</div>
			</div>
		</footer>
	);
}

export default Footer;
