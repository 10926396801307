export function scrollUp(time){
	
	time = time || 20;
	
	const c = document.documentElement.scrollTop || document.body.scrollTop;
	
	if (c > 0) {
		window.requestAnimationFrame(() => scrollUp());
		window.scrollTo(0, c - c / time);
	}
	
}


export function timelineScroll(el,yPos){

	if (el.length === 0) { return false; }

	// console.log('hjere!');

	var tlineRect, tlineOffset, tlineY, tlineH, scrollbarH = 0;
	var triggerOffset = window.innerHeight*0.66;

	tlineRect = el.getBoundingClientRect();

	tlineOffset = {
		top: tlineRect.top + window.scrollY,
		left: tlineRect.left + window.scrollX,
	};
	
	tlineY = tlineOffset.top ? tlineOffset.top : 0;
	
	tlineH = el.offsetHeight;
	
	// console.log(yPos, parseInt(yPos+triggerOffset), tlineY);

	if ( parseInt(yPos+triggerOffset) > tlineY ){

		// add active class to list elements
		
		var tlChildren = el.querySelectorAll('.timeline-item');
		
		if (tlChildren){
			
			// console.log(tlChildren);
			
			tlChildren.forEach(function(item,idx){
				var dRect = item.getBoundingClientRect();
				var dOffset = {
					top: dRect.top + window.scrollY,
					left: dRect.left + window.scrollX,
				}
				
				var dY = dOffset.top ? dOffset.top : 0;
				
				if ( parseInt(yPos+triggerOffset-24) > dY ){
					item.classList.add('active');
					//console.log('date trigger!', dY);
				} else {
					item.classList.remove('active');
				}
			});
			/*
			*/

			
		}
		

		// calculate active scrollbar height

		scrollbarH = parseInt(yPos + triggerOffset - tlineY);


		let progressBar = el.querySelectorAll('.progressbar');
		
		if (progressBar){
			
			if ( scrollbarH <= tlineH ) {
				
				progressBar = progressBar[0];
				
				// console.log(progressBar);
				// console.log(scrollbarH);
				
				if ( (tlineH / scrollbarH) > 85 ) {
					// progressBar.style.transition = 'all 0.5s ease';
					progressBar.style.height = 0;
				} else if ( (tlineH / scrollbarH) > 1.05 ) {
					// progressBar.style.transition = "";
					progressBar.style.height = scrollbarH+"px";
				} else {
					// progressBar.style.transition = 'all 0.5s ease';
					progressBar.style.height = tlineH+"px";
				}
				/*
				*/
				
	
			}
			
		}
		

	}
}
