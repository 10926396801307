import React from 'react';
import CptCarousel from '../../components/CptCarousel';
import ContentCarousel from '../../components/ContentCarousel';

function WpBlockCarousel(props) {

	const block = props.block;
	
	const carouselProps = {
		title: block.attrs.data.title ? block.attrs.data.title : "Artworks",
		className: block.attrs.className ? block.attrs.className : "",
		archiveLink: block.attrs.data.archiveLink ? block.attrs.data.archiveLink : {},
		postType: block.attrs.data.postType ? block.attrs.data.postType : "custom",
		postsN: parseInt( block.attrs.data.postsN ? block.attrs.data.postsN : 12 ),
		ipsDesktop: parseInt( block.attrs.data.ipsDesktop ? block.attrs.data.ipsDesktop : 4 ),
		ipsTablet: parseInt( block.attrs.data.ipsTablet ? block.attrs.data.ipsTablet : 2 ),
		ipsMobile: parseInt( block.attrs.data.ipsMobile ? block.attrs.data.ipsMobile : 1 ),
		arrows: block.attrs.data.arrows ? block.attrs.data.arrows : 1,
		dots: block.attrs.data.dots ? block.attrs.data.dots : 0,
		reloadBtn: block.attrs.data.reloadBtn ? block.attrs.data.reloadBtn : 0,
	};
	
	if (block.blockName === "acf/content-carousel"){
		
		const content_array = block.attrs.data.content_array;
		//const contenuti_array = [];
		
		return(
			<ContentCarousel carouselProps={{...carouselProps}} carouselData={content_array} />
		);
		
	} else {
		
		return(
			<CptCarousel carouselProps={{...carouselProps}} />
		);
		
	}
	
}

export default WpBlockCarousel;
