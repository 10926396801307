import React from 'react';
import {NavLink} from 'react-router-dom';
import './Breadcrumbs.scss';

const Breadcrumbs = (props) => {
	
	let crumbs = [];
	
	const homeCrumb = {
		id: 0,
		title: "Home",
		url: "/"
	}
	
	if (props.parents){
		crumbs = [homeCrumb, ...props.parents];
	}
	
	return (
		<ul className="breadcrumbs">
		{
			crumbs.map( (crumb,idx) => {
				return (
					<li key={idx} className="crumb"><NavLink exact to={crumb.url}>{crumb.title}</NavLink></li>
				)
			})
		}
		<li className="crumb">{props.curPageTitle}</li>
		</ul>
	)
}

export default Breadcrumbs
