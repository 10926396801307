// production

const IMC = {
	home: 'https://imagomundicollection.org/',
	content: 'https://admin.imagomundicollection.org/',//
	media: 'https://imagomundi-wp.s3.amazonaws.com/',
};


// localhost
/* const IMC = {
	home: "http://localhost:3000/",
	content: "http://imagomundicollection.local/",
	media: "https://imagomundi-wp.s3.amazonaws.com/",
}; */

const WPAPI = {
	menu: 'wp-json/imc/v1/menu/',
	pages: 'wp-json/imc/v1/page/',
	cf7: 'wp-json/contact-form-7/v1/contact-forms/',
	artists: 'wp-json/wp/v2/artist/',// not used
	artworks: 'wp-json/imc/v1/artworks/',
	catalogues: 'wp-json/imc/v1/catalogues/',
	exhibitions: 'wp-json/imc/v1/exhibitions/',
	mapsProjects: 'wp-json/imc/v1/maps-projects/',
	searchUrl: 'wp-json/imc/v1/search/',//
};

/*
*/
export { IMC, WPAPI }

// export default WPAPI;
