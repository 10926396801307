import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import parse from 'html-react-parser';
import $ from 'jquery';
import { IMC } from '../services/wpClient';
import {scrollUp} from '../services/helpers.js';
import ImageGallery from '../components/ImageGallery';
import BlockParser from '../components/BlockParser';
import Breadcrumbs from '../components/Breadcrumbs';
// import useScript from '../hooks/useScript';
import LoadingIcon from '../components/LoadingIcon';
import "./catalogues.scss";

function Catalogue({match}){
	
	const [isLoading,setLoading] = useState(true);
	
	const [catalogue,setCatalogue] = useState({
		slug: "",
		title: "",
		content: "",
		artworksCount: "",
		regions: [],
		gallery: [],
		shopLink: "",
		gArtsLink: "",
		pdf: "",
		meta: [],
	});
	
	const sliderSettings = {
		dots: true,
		arrows: false,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1
	};
	
	const [catalogueBlocks,setCatalogueBlocks] = useState([]);
	
	
	useEffect( () => {
	
		let blocksBuffer = [];
		
		const fetchCatalogue = async () => {
			
			scrollUp();
			
			try {
				
				setLoading(true);
				
				const fetchCatalogueItem = await fetch(IMC.content+`wp-json/imc/v1/catalogues/${match.params.slug}`);
				
				const data = await fetchCatalogueItem.json();
				
				if (data){
					
					setCatalogue(data);
					
					if (data.has_blocks === true && data.blocks.length){
						
						data.blocks.map( (block,idx) => {
							
							// remove gallery block
							if (block.blockName !== "core/gallery"){
								
								const renderedBlock = <BlockParser block={block} key={`block-${idx}`} />;
								blocksBuffer.push( renderedBlock );
								
							}
							
							return true;
							
						} );
						
						setCatalogueBlocks(blocksBuffer);
						
					} else {
						
						if (data.hasOwnProperty('content')){
							
								blocksBuffer = data.content.rendered;
								setCatalogueBlocks(blocksBuffer);
							if (data.content){
							}
							
						}
						
					}
					
				} else {
					
					setCatalogueBlocks([]);
					
				}
				
				
				$(document).on('click',".df-lightbox-close",function(){
					
					// disable button 
					$(document).find('._df_custom').removeClass('_df_custom').addClass('_df_custom_disabled');
					
					setTimeout(function(){
						//$(document).find('.df-lightbox-wrapper').html("");
						$(document).find('._df_custom_disabled').removeClass('_df_custom_disabled').addClass('_df_custom');
						// console.log('removed!');
					},1000);
				});
				
				
				
			} catch(error) {
				
			} finally {
				
				setLoading(false);
				
			}
			
		};
		
		// const [isModalOpen,setModalOpen] = useState(false);
		
		fetchCatalogue();
	}, [match.params.slug] );
	
	return (
		<>
		{
			isLoading ? <LoadingIcon /> : (
				
				<section className="single-catalogue">
					<Helmet>
						<title>{catalogue.meta.title}</title>
						<meta property="description" content={catalogue.meta.description} />
						<meta property="og:url" content={IMC.home+'catalogues/'+catalogue.slug} />
						<meta property="og:type" content="article" />
						<meta property="og:title" content={catalogue.meta.title} />
						<meta property="og:description" content={catalogue.meta.description} />
						<meta property="og:image" content={catalogue.meta.image} />
						<script src="/libs/jquery.min.js" async type="text/javascript" />
						<script src="/libs/dflip/js/dflip.min.js" async type="text/javascript" />
						<script src="/libs/dflip-custom.js" async type="text/javascript" />
						<link rel="stylesheet" href="/libs/dflip/css/dflip.min.css"  type = "text/css" />
						<link rel="stylesheet" href="/libs/dflip/css/themify-icons.min.css"  type = "text/css" />
					</Helmet>
					<article id={catalogue.id}>
						<main>
							<Breadcrumbs parents={[{title: "Catalogues", url: "../"}]} curPageTitle={parse(catalogue.title)} />
							{ catalogue.gallery && (
								<ImageGallery images={catalogue.gallery} sliderSettings={sliderSettings} />
								)
							}
							<div className="article-content catalogue-content">
								<h5 className="title__s uppercase label">Catalogue</h5>
								<h1 className="article-title catalogue-title">{parse(catalogue.title)}</h1>
								<div className="text">
									{catalogueBlocks}
								</div>
								{
									catalogue.gArtsLink && (
										<p><a href={catalogue.gArtsLink} className="button underline" target="_blank" rel="noreferrer">View on Google Arts &amp; Culture</a></p>
									)
								}
								<p style={{marginTop: "var(--pad)"}}><Link to={`/artworks/?catalogues=${catalogue.id}`} className="cta button wider">View the catalogue's artworks</Link></p>
							</div>
						</main>
						<aside>
							<div className="catalogue-meta">
								<h3 className="catalogue-title">{parse(catalogue.title)}</h3>
								{ catalogue.pdf && (
									<span className="open-book"><a href={catalogue.pdf} className="_df_custom" source={catalogue.pdf} id="df_manual_button">Read catalogue</a></span>
								)}
							</div>
							<div id="catalogue-info">
								<h5>Artworks in this catalogue</h5>
								<p><Link to={`/artworks/?catalogues=${catalogue.id}`} className="button arrow">View all artworks</Link></p>
							</div>
							{
								catalogue.shopLink && (
									<div id="catalogue-store-link">
										<h5>Buy the catalogue</h5>
										<p><a href={catalogue.shopLink} target="_blank" rel="noreferrer" className="button arrow">Go to Fabrica Shop</a></p>
									</div>
								)
							}
						</aside>
					</article>
				</section>
			)
		}
		</>
	);
	
	
}

export default Catalogue
