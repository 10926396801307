import React, {useState, useEffect} from 'react';
import parse from 'html-react-parser';
import Slider from "react-slick";
import "./carousel.scss";

//import Carousel from 'react-gallery-carousel';
//import 'react-gallery-carousel/dist/index.css';

/*
let clickTimeout = null;
let disableClick = false;

function resumeClick(e){
	setTimeout(()=>{ disableClick = false; },100);
	clearTimeout(clickTimeout);
}

function pauseClick(e){			
	clickTimeout = setTimeout(()=>{ disableClick = true; },200);
}

function handleClick(e){
	if (disableClick == true){ e.preventDefault(); }
}
*/


function ContentCarousel(props){
	
	// const [isLoading,setLoading] = useState([]);
	// const [slides,setSlides] = useState([]);
	
	let slides = [];
	
	const [carouselProps,setCarouselProps] = useState({...props.carouselProps});
	const [carouselData,setCarouselData] = useState([]);
	
	useEffect(() => {
		setCarouselProps(props.carouselProps);
		setCarouselData(props.carouselData);
		// setSlides(props.slides);
	},[props.carouselProps,props.carouselData]);
	
	let tempSlides = [];
	
	// console.log(carouselProps);
	
	// setLoading(true);

	if (carouselData.length){

		carouselData.map((item, idx) => {
			
			let itemHTML = (
				<div className="slide-content" key={idx}>
					<div className="image"><img src={item.image} alt="" /></div>
					<div className="item-info">
						<h3 className="title__m item-title">{parse(item.title)}</h3>
						<span className="title__xxs uppercase grey weight-400 item-subtitle">{parse(item.subtitle)}</span>
						{
							item.links && (
								<ul className="links-list">
									{
										item.links.map((link,lIdx) => {
											return(
												<li key={lIdx}><a href={link.url} className="button underline" target={link.target} rel={ link.target === "_blank" ? "noreferrer" : undefined }>{link.title}</a></li>
													
											)
										})
									}
								</ul>
							)
						}
					</div>
				</div>
			);
			
			tempSlides.push({content: itemHTML });
			
			return true;
			
		});
	
		slides = tempSlides;
		
	}

	
	// setLoading(false);

	const settings = {
		dots: carouselProps.dots,
		arrows: carouselProps.arrows,
		infinite: true,
		speed: 500,
		slidesToShow: carouselProps.ipsDesktop,
		slidesToScroll: carouselProps.ipsDesktop,
		// adaptiveHeight: true,
		responsive: [
			{
				breakpoint: 1023,
				settings: {
					slidesToShow: carouselProps.ipsTablet,
					slidesToScroll: carouselProps.ipsTablet,
					dots: true,
					arrows: false,
				}
			},
			{
				breakpoint: 767,
				settings: {
					slidesToShow: carouselProps.ipsMobile,
					slidesToScroll: carouselProps.ipsMobile,
					dots: true,
					arrows: false,
				}
			}
		]
	};
	
	
	return(
		<>
		{ carouselData && (
				<section className={`carousel-container ${carouselProps.className}`}>
					<header className="section-header">
						<h3 className="title__xl section-title">{carouselProps.title}</h3>
						{ carouselProps.archiveLink && (
							<a href={carouselProps.archiveLink.url} target={carouselProps.archiveLink.target} rel={ carouselProps.archiveLink.target === "_blank" ? "noreferrer" : undefined }>
								<button className="underline">{carouselProps.archiveLink.title}</button>
							</a>
						)}
					</header>
					<Slider {...settings} className={`slider-post-type-${carouselProps.postType} desktop-cols-${carouselProps.ipsDesktop} tablet-cols-${carouselProps.ipsTablet} mobile-cols-${carouselProps.ipsMobile}`}>
						{
							slides.map((slide,l) => {
								return (
									<div className="slide" key={l}>{slide.content}</div>
								)
							})
						}
					</Slider>
				</section>
			)
		}
		</>
	);
}

export default ContentCarousel;
