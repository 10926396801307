import React, {useState, useCallback} from 'react';
import { useHistory } from 'react-router-dom';
//import { debounce } from 'lodash';
import PostsFilter from '../components/PostsFilters';
import {ReactComponent as ClearIcon} from '../img/clear.svg';
import './SearchForm.scss';


function SearchForm({search, onSearchSubmit, searchParams, setSearchParams, filterObj, setFilterObj, handleFiltersChange, handleSearchChange, placeholder, archiveType, enabledFilters, value}){
	
	const [isTouched,setTouched] = useState(false);
	const [searchVal, setSearchVal] = useState(value);
	
	let history = useHistory();
	
	const handleSubmit = useCallback(e => {
		
		e.preventDefault();

		let search = e.target.search.value ? e.target.search.value : "";

		setTouched(true);
		
		const newSearchParams = {
			search: search,
		};		
		
		setSearchVal(search);
		onSearchSubmit(newSearchParams);
		history.push("/"+archiveType+"?s="+search);
		
	},[onSearchSubmit, history, archiveType]);
	
	const handleClearSearch = (e) => {
		
		e.preventDefault();
		
		document.getElementById('search').value = "";
		onSearchSubmit({ search: "" });
		setSearchVal(e.target.value);
		setTouched(false);
		history.push("/"+archiveType+"/");
		
	}
	
	const handleChange = (e) => {
		setSearchVal(e.target.value);
	}
	
	
	// const handleSearchChangeThrottled = debounce(handleChange, 300);
	
	return(
		<form className="search-form" onSubmit={handleSubmit}>
			<div className="searchbox-container">
				<div className="field">
					<input type="text" placeholder={placeholder || "Enter search terms"} id="search" name="search" onChange={handleChange} value={searchVal} />
					{ isTouched && (
						<button onClick={handleClearSearch} className="clear-search" type="button"><ClearIcon /></button>
					) }
				</div>
				<button className="button cta" type="submit">Search</button>
			</div>
			<PostsFilter enabledFilters={enabledFilters} handleFiltersChange={handleFiltersChange} searchParams={searchParams} setSearchParams={setSearchParams} archiveType={archiveType} />
		</form>
	);
}

export default SearchForm;
