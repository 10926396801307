import React, {useState, useEffect} from 'react';
import { IMC, WPAPI } from '../../services/wpClient';
import CustomContentModal from '../../components/CustomContentModal';
// import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';


function WpBlockMapsProjects(props) {
	
	const [isModalOpen, setModalOpen] = useState(false);
	const [mapsProjects, setMapsProjects] = useState([]);
	const [selectedArtwork, setSelectedArtwork] = useState([]);
	
	// isModalOpen ? disableBodyScroll(document) : enableBodyScroll(document);

	const fetchMapsProjects = async () => {
		
		const postsURL = new URL(IMC.content+WPAPI.mapsProjects);
		
		const urlParams = {
			maxposts: -1,
			offset: 0,
			rand: 0,
		};
		
		postsURL.search = new URLSearchParams(urlParams);
		const response = await fetch(postsURL);
		const data = await response.json();
		
		if (data.items){ setMapsProjects(data.items); }
		
	}
	
	const handleClick = (content) => {
		console.log(content);
		setSelectedArtwork(content);
		setModalOpen(true);
	}

	useEffect(() => {
		fetchMapsProjects();
	}, []);

	
	return (
		<div className="section-content" style={null}>
			<ul className="grid posts-grid">
				{
					mapsProjects.map( (item) => {
						console.log(item);
						return(
							<li key={item.id} className="grid-item" onClick={() => handleClick({gallery: item.gallery, content: item.content})}>
								<div className="image"><img src={item.bgImage} alt="" /></div>
								<div className="post-info">
									{ item.artist && (
										<h5 className="title__xs uppercase post-artist">{item.artist}</h5>
									) }
									<h4 className="title__s post-title">{item.title}</h4>
								</div>
							</li>
						)
					})
				}
			</ul>
			<CustomContentModal modalContent={selectedArtwork} isModalOpen={isModalOpen} setModalOpen={setModalOpen} />
		</div>
	);
	
}

export default WpBlockMapsProjects;
