import React from 'react';
import parse from 'html-react-parser';
import BlockParser from '../../components/BlockParser';

function WpBlockCover(props) {
	
	const block = props.block;
	
	const coreCover = {};
	
	coreCover.parentBlock = parse(block.innerContent[0].replace("\n",""));
	coreCover.parentBlockClassName = coreCover.parentBlock.props.className;
	
	coreCover.innerBlocks = [];
	
	if (block.innerBlocks.length){
		block.innerBlocks.map((innerBlock,id) => {
			coreCover.innerBlocks.push(<BlockParser block={innerBlock} key={innerBlock.blockName+"-"+id} />);
			return true;
		});
	}
	
	let coverClassName = "wp-block-cover";
	coverClassName += block.attrs.href ? " has-link" : "";
	coverClassName += block.attrs.className ? " "+block.attrs.className : "";
	
	coreCover.innerBlocks = block.attrs.href ? <a href={block.attrs.href} target={block.attrs.opensInNewTab === true ? "_blank" : "_self"} rel={block.attrs.opensInNewTab === true ? "noreferrer" : undefined}>{coreCover.innerBlocks}</a> : coreCover.innerBlocks;
	
	return(<>
		<div className={coverClassName} key={block.attrs.id} >
			<img className={`wp-block-cover__image-background wp-image-${block.attrs.id}`} alt="" src={block.attrs.url} data-object-fit="cover"/>
			<div className="wp-block-cover__inner-container">
				{coreCover.innerBlocks}
			</div>
		</div>
	</>);
	
}

export default WpBlockCover;
