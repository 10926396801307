import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import Flippy, { FrontSide, BackSide } from 'react-flippy';
import parse from 'html-react-parser';
import COUNTRIES from '../services/countries';
import { IMC } from '../services/wpClient';
import Breadcrumbs from '../components/Breadcrumbs';
import LoadingIcon from '../components/LoadingIcon';
import "./artworks.scss";
import "./FlippableImage.scss";


function Artwork({match}){
	
	const [isLoading,setLoading] = useState(true);
	
	const [artworkIsFlipped,setArtworkIsFlipped] = useState(false);
	
	const [artwork,setArtwork] = useState({
		title: "",
		artist: {
			name: "",
			bio: ""
		},
		date: "",
		continent: [],
		region: [],
		thumb: [],
		front_image: "",
		back_image: "",
		catalogues: [],
		size: "",
		materials: [],
		colors: [],
		tags: [],
		meta: [],
	});
	
	const sidesStyle = {
		
		boxShadow: "none",
		// maxWidth: "40vw",
		height: "auto",
		
	}
	
	
	useEffect( () => {
		
		const fetchArtwork = async () => {
			
			setLoading(true);
			
			window.scroll({
				top: 0,
				left: 0,
				behavior: 'smooth'
			});
			
			const fetchArtworkItem = await fetch(IMC.content+`wp-json/imc/v1/artworks/${match.params.slug}`);
			
			const data = await fetchArtworkItem.json();
			
			setArtwork(data);
			
			setLoading(false);
			
			setTimeout(() => {
				setArtworkIsFlipped(true);
				setTimeout(() => {
					setArtworkIsFlipped(false);
				}, 1500);
			}, 1000);			
		
		};
		
		
		fetchArtwork();
	}, [match.params.slug] );
	
	// <FrontSide className="image-front" style={{...sidesStyle}}>{parse(artwork.front_image)}</FrontSide>
	// <BackSide className="image-back" style={{...sidesStyle}}>{parse(artwork.back_image)}</BackSide>
	
	return (
		<>
			{
				isLoading === true ? ( <LoadingIcon />) : (
					<section className="single-artwork">
						<Helmet>
							<title>{artwork.meta.title}</title>
							<meta property="description" content={artwork.meta.description} />
							<meta property="og:url" content={IMC.home+'artworks/'+artwork.slug} />
							<meta property="og:type" content="article" />
							<meta property="og:title" content={artwork.meta.title} />
							<meta property="og:description" content={artwork.meta.description} />
							<meta property="og:image" content={artwork.meta.image} />
						</Helmet>
						<article id={artwork.id}>
							<main>
								<Breadcrumbs parents={[{title: "Artworks", url: "../"}]} curPageTitle={artwork.artist.name} />
								<div className={`flippable-image orientation-${artwork.orientation}`}>
									<Flippy className="flippable-image-content" flipOnClick={true} isFlipped={artworkIsFlipped}>
										<FrontSide className="image-front" style={{...sidesStyle}}><img src={IMC.media+"artworks/"+artwork.front_image_filename} alt={artwork.front_image_filename+" front"} /></FrontSide>
										<BackSide className="image-back" style={{...sidesStyle}}><img src={IMC.media+"artworks/"+artwork.back_image_filename} alt={artwork.back_image_filename+" back"} /></BackSide>
									</Flippy>
								</div>
								<div className="article-content artist-info">
									<h5 className="title__s uppercase label">Artist</h5>
									<h3 className="title__xl article-title">{artwork.artist.name}</h3>
									<div className="text">
										{parse(artwork.artist.bio)}
									</div>
									{artwork.catalogues.map((catalogue) => <Link key={`catalogue-${catalogue.id}`} to={`/artworks?catalogues=${catalogue.id}`}><button className="button wider cta">Go to Collection</button></Link>)}
								</div>
							</main>
							<aside>
								<div id="artwork-meta" className="artwork-meta-group">
									<h4 className="artist">{parse(artwork.artist.name)}</h4>
									<h3 className="artwork-title">{parse(artwork.title)}</h3>
									<span className="artwork-region">{artwork.date.substr(0,4)}, <span>{COUNTRIES.name[artwork.region]}</span></span>
								</div>
								<div id="artwork-catalogues" className="artwork-meta-group">
									<h5>Catalogue</h5>
									<ul>
										{artwork.catalogues.map((catalogue) => <li key={`catalogue-${catalogue.id}`}><Link to={`/catalogues/${catalogue.slug}/`} className="button arrow">{catalogue.title}</Link></li>)}
									</ul>
								</div>
								<div id="artwork-info" className="artwork-meta-group">
									<h5>Artwork info</h5>
									<ul>
										<li>Size:<br/>{artwork.size}</li>
										{ artwork.materials.length > 0 && (
											<li>Materials:<br/><ul>{artwork.materials.map((material) => <li key={`material-${material.id}`}>{material.name}</li>)}</ul></li>
										)}
									</ul>
								</div>
								{ artwork.tags.length > 0 && (
								<div id="artwork-tags" className="artwork-meta-group">
									<h5>Tags</h5>
									<ul>
										{artwork.tags.map((tag) => <li key={`tag-${tag.id}`}><Link to={`/artworks?s=${encodeURIComponent(tag.name)}`} className="button cta">{tag.name}</Link></li>)}
									</ul>
								</div>
								)}
							</aside>
						</article>
					</section>
				)
			}
			</>
	);
	
	
}

export default Artwork
