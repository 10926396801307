import {NavLink} from 'react-router-dom';
import {ReactComponent as IconSearch} from '../img/search.svg';

function UISearchButton({isMenuOpen, setMenuOpen}){
	
	return(
		<NavLink exact to={`/search/`} onClick={() => { setMenuOpen(false)} } className="button searchbutton">
			<IconSearch />
			<span className="label">SEARCH</span>
		</NavLink>
	);
}

export default UISearchButton;
