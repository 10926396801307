import React from 'react';
import Nav from '../components/nav';
import UISocialButtons from '../components/UI-SocialButtons';
import "./modalMainMenu.scss";


const ModalMainMenu = ({isMenuOpen,setMenuOpen}) => {
	
	const modal = isMenuOpen && (
		<div className="modal menu-modal">
			<div className="modal-content">
				<Nav id="main-nav" menuID="main-menu" isMenuOpen={isMenuOpen} setMenuOpen={setMenuOpen} />
				<Nav id="secondary-nav" menuID="secondary-menu" isMenuOpen={isMenuOpen} setMenuOpen={setMenuOpen} />
				<UISocialButtons mode="icons" />
			</div>
		</div>
	);
	
	return modal;
}

export default ModalMainMenu;
