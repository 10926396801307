import React, {useState, useEffect, useCallback} from 'react';
import {Helmet} from 'react-helmet';
import { Link, useHistory } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
// import { debounce } from 'lodash';
import parse from 'html-react-parser';
import * as qs from 'query-string';
import { IMC, WPAPI } from '../services/wpClient';
import COUNTRIES from '../services/countries';
// import CONTINENTS from '../services/continents';
import {scrollUp} from '../services/helpers.js';
// import CachedContent from '../components/CachedContent';
import Breadcrumbs from '../components/Breadcrumbs';
import SearchForm from '../components/SearchForm';
import LoadingIcon from '../components/LoadingIcon';
import "./artworks.scss";


const maxPosts = 24;


function ArtworksArchive({...props}){
	
	let history = useHistory();
	
	const urlParams = qs.parse(props.location.search);

	
	// console.log(urlParams);
	
	const [artworks,setArtworks] = useState([]);
	const [total,setTotal] = useState([]);
	const [returnedIDs, setReturnedIDs] = useState([]);
	const [newResults, setNewResults] = useState([]);
	const [page,setPage] = useState(0);
	const [isLoading,setLoading] = useState(true);
	// const [filtersOpen, setFiltersOpen] = useState(true);
	// eslint-disable-next-line
	const [search,setSearch] = useState({
		s: urlParams.s ? urlParams.s : ""
	});
	
	const [searchParams, setSearchParams] = useState({
		s: urlParams.s ? urlParams.s : "",
		continents: urlParams.continents ? urlParams.continents : "", 
		regions: urlParams.regions ? urlParams.regions : "", 
		catalogues: urlParams.catalogues ? urlParams.catalogues : "", 
		exhibitions: urlParams.exhibitions ? urlParams.exhibitions : "",
	});
	
	const [searchQuery, setSearchQuery] = useState({
		...searchParams,
		// page: page,
		// maxposts: maxPosts,
	});
	
	const handleSearchSubmit = useCallback(e => {
		
		//e.preventDefault();

		setPage((page) => 0);
		setArtworks((artworks) => []);
		setReturnedIDs((returnedIDs) => []);
		
		let searchVal = e.search ? e.search : "";
		
		setSearchParams({...searchParams, s: searchVal});
		
		const newSearchQuery = {
			...searchParams,
			page: page,
			maxposts: maxPosts,
		};
		
		// console.log("search changed!",newSearchQuery);
		
		setSearchQuery(newSearchQuery);
		
		// console.log("searchParams:",searchParams,search);
		
		if (searchVal){
			
			// console.log(searchParams);
			
			// remove empty params
			const cleanParams = Object.keys(searchParams)
				.filter((k) => searchParams[k])
				.reduce((a,k) => ({...a, [k]: searchParams[k] }), {} );
			
			const flatParams = qs.stringify( cleanParams, {arrayFormat: 'comma'});
			
			
			history.push('/artworks?'+flatParams);
			
		}
		
		
	},[history, page, searchParams]);
	
	const handleSearchChange = useCallback(e => {
		
		e.preventDefault();

		setPage((page) => 0);
		setArtworks((artworks) => []);
		setReturnedIDs((returnedIDs) => []);
		
		let searchVal = e.target.value ? e.target.value : "";
		
		setSearchParams({...searchParams, s: searchVal, page: page, maxposts: maxPosts});
		
		setSearchQuery(searchParams);
		
	},[page, searchParams]);
	
	
	const handleFiltersChange = useCallback(
		(changedSearchParams) => {
			
			setPage((page) => 0);
			setArtworks((artworks) => []);
			setReturnedIDs((returnedIDs) => []);
			setTotal(0);
			
			// const searchEl = document.getElementById('search');
			
			const newSearchParams = {
				...searchParams,
				...changedSearchParams,
				page: page,
				maxposts: maxPosts,
			};
			
			// console.log(newSearchParams);
			
			setSearchParams({...searchParams, ...changedSearchParams});
			
			setSearchQuery(newSearchParams);
		},
		[page, searchParams],
	);
	
	
	useEffect(() => {
		
		const fetchArtworks = async () => {
			
			if (page === 0){ scrollUp() }
			
			try {
				
				setLoading(true);
				
				const artworksURL = new URL(IMC.content+WPAPI.artworks);
				
				const urlParams = {
					...searchParams,
					maxposts: maxPosts,
					not_in: returnedIDs,
					rand: 1,
				};
				
				artworksURL.search = new URLSearchParams(urlParams);
				
				const response = await fetch(artworksURL);
				const data = await response.json();
				const items = data.items;
				const total = data.total;
				
				const artworkIDs = items.map((artwork)=>{
					return artwork.id;
				});
				
				setNewResults(items.length);
				
				setReturnedIDs((returnedIDs) => [...returnedIDs, ...artworkIDs]);
				
				setArtworks((artworks) => [...artworks, ...items]);
				
				setTotal(total);
				
			} catch (error){
				
				console.log('error loading artworks:', error);
				
			} finally {
				
				// console.log('done searching!');
				setLoading(false);
				
			}
			
		}
		
		// console.log(searchQuery);
		fetchArtworks();
		// eslint-disable-next-line
	},[page,searchQuery]);
	
	const loadMore = () => {
		setPage((page) => page + 1);
	};
	
	return(
		<>
			<Helmet>
				<title>Imago Mundi Collection - Artworks</title>
			</Helmet>
			<div className="page archive artworks-container">
				<header className="page-header">
					<div className="page-header-content">
						<Breadcrumbs parents={[]} curPageTitle={"Artworks"} />
						<h1>Artworks</h1>
					</div>
					<SearchForm placeholder="Search by Artist, Keyword, Tag" onSearchSubmit={handleSearchSubmit} searchParams={searchParams} setSearchParams={setSearchParams} handleSearchChange={handleSearchChange} handleFiltersChange={handleFiltersChange} archiveType="artworks" enabledFilters={['continents','regions','catalogues','exhibitions']} value={search.s} />
					<div className="results-count aligncenter">
					{
						(isLoading && artworks.length === 0) ? <LoadingIcon />
						: [
							artworks.length > 0 ?
								<p>showing <strong>{artworks.length}</strong> of <strong>{total}</strong> artworks found</p>
								: <p>No Artworks match your search criteria.</p>
						]
					}</div>
				</header>
				{
						artworks.length > 0 ? (
							<>
							<ul className="archive-grid artworks-grid">
							{artworks.map((art) => {
								
								const front_image_thumb = IMC.media+"artworks/"+art.thumb;
								const year = art.year;
								const region = art.region;
								const country = COUNTRIES.name[region];
								
								return (
								<li key={art.id} id={art.id} className="archive-grid-item artwork-item">
									<Link to={`/artworks/${art.slug}/`}>
										<div className="grid-item-image-container">
											<img src={front_image_thumb} width={200} className="grid-item-image artwork-image" alt={parse(art.artist)+" - "+parse(art.title)} />
										</div>
										<div className="grid-item-meta">
											<h3 className="title__m artwork-artist">{parse(art.artist)}</h3>
											<h4 className="title__n artwork-title">{parse(art.title)}</h4>
											<span className="title__xxs uppercase weight-400 grey">{year}, {country}</span>
										</div>
									</Link>
								</li>)
									
								}
								)
								
							}
							</ul>
							{ newResults >= maxPosts && (
								<div className="load-more">
									{ isLoading ?
										( <LoadingIcon /> ) :
										( <button onClick={loadMore} className="button cta">Show More</button>)
									}
								</div>
							)
							}
							</>
						) : (
							null
						)
						
						
				}
			</div>
		</>
		
	);
	
}


export default withRouter(ArtworksArchive);
