import React, {useState, useEffect} from 'react';
import {Helmet} from 'react-helmet';
import { IMC, WPAPI } from '../services/wpClient';
import {scrollUp} from '../services/helpers.js';
import BlockParser from '../components/BlockParser';
import LoadingIcon from '../components/LoadingIcon';



function PageHome(props){
	
	const [isLoading,setLoading] = useState(true);
	// const [homeContent, setHomeContent] = useState([]);
	const [homeBlocks, setHomeBlocks] = useState([]);
	
	
	useEffect( () => {
		
		let buffer = [];
		
		const fetchHome = async () => {
			
			setLoading(true);
			 
			const homeUrl = new URL(IMC.content+WPAPI.pages+"home");
			
			const data = await fetch(homeUrl);
			
			const homeObj = await data.json();
			
			if (homeObj[0].has_blocks === true && homeObj[0].blocks.length){
				
				homeObj[0].blocks.map( (block,idx) => {
					const renderedBlock = <BlockParser block={block} key={`block-${idx}`} />;
					buffer.push( renderedBlock );
					return true;
				} );
				
				setHomeBlocks(buffer);
				setLoading(false);
				
			} else {
				if (homeObj[0].hasOwnProperty('content')){
					//const homeContent = ;
					// setHomeContent(homeObj[0].content.rendered);
					setLoading(false);
				}
			}
			
			scrollUp();
			
		}
		
		fetchHome();
		
	},[] );

	
	return (
		<div className="page home">
			<Helmet>
				<title>Imago Mundi Collection</title>
			</Helmet>
			{
				isLoading === true ? ( <LoadingIcon />) : (
					homeBlocks
				)
			}
		</div>
	);
	
}

export default PageHome;
