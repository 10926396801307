import React, {useState, useEffect} from 'react';
import { useHistory } from 'react-router-dom';
import Select, { components } from 'react-select';
import AsyncSelect from 'react-select/async';
import { IMC, WPAPI } from '../services/wpClient';
import COUNTRIES from '../services/countries';
import CONTINENTS from '../services/continents';
import {ReactComponent as ClearIcon} from '../img/clear.svg';
import {ReactComponent as ChevronDownIcon} from '../img/chevron-down.svg';
import './PostsFilters.scss';


function sortOptsBylabel(a,b){
	if ( a.label < b.label ){
		return -1;
	}
	if ( a.label > b.label ){
		return 1;
	}
	return 0;
}

const selectTheme = (theme) => ({
	...theme,
	borderRadius: 0,
	textTransform: 'uppercase',
});



const ClearIndicator = props => {
	const {
		children = <ClearIcon />,
		getStyles,
		innerProps: { ref, ...restInnerProps },
	} = props;
	return (
		<div
			{...restInnerProps}
			ref={ref}
			style={getStyles('clearIndicator', props)}
		>
			<div className="clear-indicator">{children}</div>
		</div>
	);
};


const DropdownIndicator = (
	props: ElementConfig<typeof components.DropdownIndicator>
) => {
	return (
		<components.DropdownIndicator {...props}>
			<ChevronDownIcon />
		</components.DropdownIndicator>
	);
};


const continents = Object.entries(CONTINENTS).map( ([key,value]) => {
	return (
		{value: key, label: value.toString()}
	)
} );

const regions = Object.entries(COUNTRIES.name).map( ([key,value]) => {
	return (
		{value: key, label: value.toString(), continent: COUNTRIES.continent[key] }
	)
});


continents.sort(sortOptsBylabel);
regions.sort(sortOptsBylabel);


function PostsFilter({searchParams, setSearchParams, handleFiltersChange, archiveType, enabledFilters}) {
	
	const [filtersOpen, setFiltersOpen] = useState(false);
	
	let history = useHistory();
	
	const filterItems = ([items, inputValue: string]) => {
		return items.filter(i =>
			i.label.toLowerCase().includes(inputValue.toLowerCase())
		);
	};
	
	// let catalogues = [];
	
	const [catalogueValue,setCatalogueValue] = useState();
	const [defaultCatalogue,setDefaultCatalogue] = useState();
	
	const promiseCatalogues = inputValue =>	new Promise(resolve => {
		
		const cataloguesURL = new URL(IMC.content+WPAPI.catalogues);
		
		const urlParams = {
			s: "",
			maxposts: -1,
			offset: 0,
			rand: 0,
		};
		
		cataloguesURL.search = new URLSearchParams(urlParams);
		
		fetch(cataloguesURL).then(response => {
			
			return response.json();
			
		}).then(data => {
			
			const cataloguesOptions = Object.entries(data.items).map( ([i,obj]) => {
				return (
					{value: obj.id, label: obj.title.toString(), region: obj.region}
				)
			});
			
			!defaultCatalogue &&
			setDefaultCatalogue(cataloguesOptions.find((o) => o.value === searchParams.catalogues));
			
			cataloguesOptions.sort(sortOptsBylabel);
			
			resolve(filterItems([cataloguesOptions,inputValue]));
			
		});
		
	});
	
	const promiseExhibitions = inputValue =>	new Promise(resolve => {
		
		const exhibitionsURL = new URL(IMC.content+WPAPI.exhibitions);
		
		const urlParams = {
			s: "",
			maxposts: -1,
			offset: 0,
			rand: 0,
		};
		
		exhibitionsURL.search = new URLSearchParams(urlParams);
		
		fetch(exhibitionsURL).then(response => {
			
			return response.json();
			
		}).then(data => {
			
			const exhibitionsOptions = Object.entries(data).map( ([i,obj]) => {
				return (
					{value: obj.id, label: obj.title.toString()}
				)
			});
			
			exhibitionsOptions.sort(sortOptsBylabel);
			
			resolve(filterItems([exhibitionsOptions,inputValue]));
			
		});
		
	});


	
	useEffect(() => {
		
		//setCatalogueValue(defaultCatalogue);
		
	},[defaultCatalogue]);
	
	
	
	const selContinent = continents.filter((continent)=>{
		return continent.value === searchParams.continents;
	});
	
	const selRegion = regions.filter((region)=>{
		return region.value === searchParams.regions;
	});
	
	// console.log("catalogues",promiseCatalogues);
	/*
	const selCatalogue = catalogues.filter((catalogue)=>{
		return catalogue.value === searchParams.catalogues;
	});
	*/
	
	// console.log(selCatalogue);
	
	const handleButtonClick = (e) => {
		e.preventDefault();
		setFiltersOpen(!filtersOpen);
	}
	
	return (
		<div className={`posts-filters${archiveType ? " "+archiveType+"-filters" : ""}${filtersOpen ? " filters-open" : ""}`}>
			<div className="posts-filters-content" >
				<div className="label"><button className="noborder" onClick={handleButtonClick}><span>Filter by</span><ChevronDownIcon /></button></div>
				<ul className="filters">
					{
						enabledFilters.indexOf('continents') > -1 && (
						<li className="filter" key="continents"><Select
							classNamePrefix="filter-select"
							options={continents}
							defaultValue={selContinent}
							isClearable={true}
							placeholder={'Continents'}
							components={{	ClearIndicator, DropdownIndicator }}
							theme={selectTheme}
							onChange={(values) => {
								if (values === null){ values = { value: ""};	}
								setSearchParams({ ...searchParams, continents: values.value});
								handleFiltersChange({...searchParams, continents: values.value});
							}
						}
						/></li>
						)
					}
					{
						enabledFilters.indexOf('regions') > -1 && (
							<li className="filter" key="regions"><Select
								classNamePrefix="filter-select"
								options={regions}
								defaultValue={selRegion}
								isOptionDisabled={option => searchParams.continents && option.continent !== searchParams.continents }
								isClearable={true}
								placeholder={'Regions'}
								components={{	ClearIndicator, DropdownIndicator }}
								theme={selectTheme}
								onChange={(values) => {
									if (values == null){ values = { value: ""};	}
									setSearchParams({ ...searchParams, regions: values.value});
									handleFiltersChange({ ...searchParams, regions: values.value});
								} }
							/></li>
						)
					}
					{
						enabledFilters.indexOf('catalogues') > -1 && (
							<li className="filter" key="catalogues">
								<AsyncSelect
									classNamePrefix="filter-select"
									cacheOptions
									defaultOptions
									loadOptions={promiseCatalogues}
									value={catalogueValue}
									isOptionDisabled={option => searchParams.regions && option.region.indexOf(searchParams.regions) < 0 }
									isSearchable={true}
									isClearable={true}
									placeholder={'Catalogues'}
									components={{	ClearIndicator, DropdownIndicator }}
									theme={selectTheme}
									onChange={(values) => {
										if (values == null){
											values = { value: ""};
											history.push('/artworks/');
											setCatalogueValue(null);
										} else {
											setCatalogueValue({value: values.value, label: values.label});
										}
										setSearchParams({ ...searchParams, catalogues: values.value });
										handleFiltersChange({ ...searchParams, catalogues: values.value });
									} }
									
								/>
							</li>
						)
					}
					{
						enabledFilters.indexOf('exhibitions') > -1 && (
							<li className="filter" key="exhibitions">
								<AsyncSelect
									classNamePrefix="filter-select"
									cacheOptions
									defaultOptions
									loadOptions={promiseExhibitions}
									isSearchable={true}
									isClearable={true}
									placeholder={'Exhibitions'}
									components={{	ClearIndicator, DropdownIndicator }}
									theme={selectTheme}
									onChange={(values) => {
										console.log(values);
										if (values == null){ values = { value: ""};	}
										setSearchParams({ ...searchParams, exhibitions: values.value });
										handleFiltersChange({...searchParams, exhibitions: values.value});
									} }
								/>
							</li>
						)
					}
				</ul>
			</div>
		</div>
	)
}

export default PostsFilter
