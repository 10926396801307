import React from 'react';
import Slider from "react-slick";
import "./ImageGallery.scss";

function ImageGallery({images,sliderSettings}) {
	return (
		<div className="image-gallery">
		<Slider {...sliderSettings}>
		{
			images.map( (image,index) => {
				return(<img src={image} alt={"gallery item "+index} key={index}  />)
			} )
		}
		</Slider>
	</div>
	)
}

export default ImageGallery
