import React from 'react';
import parse from 'html-react-parser';
import ImageGallery from '../components/ImageGallery';
import "./CustomContentModal.scss";

const CustomContentModal = ({modalContent, isModalOpen, setModalOpen}) => {
	
	const content = modalContent.content ? modalContent.content : "";
	const images = modalContent.gallery;
	
	const sliderSettings = {
		dots: true,
		arrows: false,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1
	};
	
	const parsedContent = content.length ? parse(content.replace("\n","")) : "";
	
	const modal = isModalOpen && (
		<div className="modal custom-content-modal">
			<button onClick={() => { setModalOpen(false)} } className="close">&times;</button>
			<div className="modal-content">
				<main>
					{ images && ( <ImageGallery images={images} sliderSettings={sliderSettings} /> ) }
					<div className="post-content">
						{parsedContent}
					</div>
				</main>
			</div>
		</div>
	);
	
	return modal;
}

export default CustomContentModal;
