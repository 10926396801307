import React, {useState} from 'react';
import Select, { components } from 'react-select';
import {ReactComponent as ClearIcon} from '../img/clear.svg';
import {ReactComponent as ChevronDownIcon} from '../img/chevron-down.svg';
import { IMC, WPAPI } from '../services/wpClient';
import {handleCF7Submit} from '../services/handleCF7Submit';
import {NavLink} from 'react-router-dom';
import "./newsletterBox.scss";


const selectTheme = (theme) => ({
	...theme,
	borderRadius: 0,
	textTransform: 'uppercase',
});

const ClearIndicator = props => {
	const {
		children = <ClearIcon />,
		getStyles,
		innerProps: { ref, ...restInnerProps },
	} = props;
	return (
		<div
			{...restInnerProps}
			ref={ref}
			style={getStyles('clearIndicator', props)}
		>
			<div className="clear-indicator">{children}</div>
		</div>
	);
};


const DropdownIndicator = (
	props: ElementConfig<typeof components.DropdownIndicator>
) => {
	return (
		<components.DropdownIndicator {...props}>
			<ChevronDownIcon />
		</components.DropdownIndicator>
	);
};

const languagesObj = {
	"it" : "Italiano",
	"en" : "English"
};

const languages = Object.entries(languagesObj).map( ([key,value]) => {
	return (
		{value: key, label: value.toString()}
	)
} );

function resetForm(form){
	
	if (form) {
		form.reset();
		form.querySelectorAll('.error').forEach((el) => { el.classList.remove('error') });
		form.querySelector('.form-messages').innerHTML = "";
	}
	
}

resetForm( document.getElementById('newsletter-form') );

function NewsletterBox(props){
	
	
	const [isSubmitEnabled, setSubmitEnabled] = useState(false);

	const toggleSubmitEnable = (e) => {
		setSubmitEnabled(!isSubmitEnabled);
	}
	
	return(
		<section id="newsletter-subscribe" className="newsletter-container" data-key={props.dataKey}>
			<header className="section-header">
				<h2 className="title__xl">Newsletter</h2>
			</header>
			<div className="grid grid-2col">
				<div className="grid-col callout-container">
					<p className="callout">Receive information and news from Imago Mundi Foundation</p>
				</div>
				<div className="grid-col form-container">
					<form id="newsletter-form" action={IMC.content+WPAPI.cf7+'50626/feedback'} method="post" onSubmit={handleCF7Submit}>
						<div className="field gap-right">
							<input type="text" name="firstname" placeholder="First name" />
						</div>
						<div className="field">
							<input type="text" name="lastname" placeholder="Last name" />
						</div>
						<div className="field gap-right">
							<input type="text" name="email" placeholder="E-mail" />
						</div>
						<div className="field">
							<input type="text" name="country" placeholder="Country" />
						</div>
						<div className="field gap-right">
							<input type="text" name="region" placeholder="Region" />
						</div>
						<div className="field">
						<Select
							classNamePrefix="newsletter-select"
							options={languages}
							isClearable={true}
							placeholder={'Language'}
							components={{	ClearIndicator, DropdownIndicator }}
							theme={selectTheme}
						/>
						</div>
						<div className="field fullwidth">
							<label className="imc-privacy-consent" htmlFor="acceptance-681" name="acceptance">
								<input type="checkbox" name="acceptance-681" id="acceptance-681" value="1" onChange={toggleSubmitEnable} />
								<span className="label-text">I have read and accept the <NavLink to="/privacy-cookie-policy">Privacy Policy</NavLink></span>
							</label>
						</div>
						<div className="field fullwidth">
							<button className="button cta" disabled={!isSubmitEnabled}>Subscribe!</button>
						</div>
						<div className="form-messages"></div>
					</form>
				</div>
			</div>
		</section>
	);
}

export default NewsletterBox;
