import React, {useState} from 'react';
import {NavLink} from 'react-router-dom';
import ModalMainMenu from '../components/Modal-MainMenu';
import UISocialButtons from '../components/UI-SocialButtons';
import UISearchButton from '../components/UI-SearchButton';
import "./header.scss";
import {ReactComponent as MenuIcon} from "../img/menu-icon.svg";
import {ReactComponent as IMCLogoInline} from "../img/IMC-logo-inline.svg";

function Header(props){
	
	const [isMenuOpen,setMenuOpen] = useState(false);
	
	return(
		<header id={props.id} className={props.className + (isMenuOpen ? " menu-open" : "")}>
			<div className="grid header-content">
				<div className="grid-col menu-toggle-container"><button className="menu-toggle" onClick={() => setMenuOpen(!isMenuOpen)}><MenuIcon/></button></div>
				<div className="grid-col logo">
					<NavLink exact to={`/`} onClick={() => { setMenuOpen(false)} }><IMCLogoInline /></NavLink>
				</div>
				<div className="grid-col header-tools">
					<div className="search-button-container">
						<UISearchButton isMenuOpen={isMenuOpen} setMenuOpen={setMenuOpen} />
					</div>
					<UISocialButtons mode="icons" />
				</div>
			</div>
			{
				isMenuOpen && (
					<ModalMainMenu isMenuOpen={isMenuOpen} setMenuOpen={setMenuOpen} />
				)
			}
		</header>
	);
}

export default Header;
