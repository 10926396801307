import React, {useState, useEffect} from 'react';
import parse from 'html-react-parser';
import {Helmet} from 'react-helmet';
import { IMC, WPAPI } from '../services/wpClient';
import {timelineScroll} from '../services/helpers.js';
import {scrollUp} from '../services/helpers.js';
// import CustomContentModal from '../components/CustomContentModal';
import Breadcrumbs from '../components/Breadcrumbs';
// import CptCarousel from '../components/CptCarousel';
import BlockParser from '../components/BlockParser';
import LoadingIcon from '../components/LoadingIcon';



function PageWithBlocks(props){
	
	const [isLoading,setLoading] = useState(true);
	// eslint-disable-next-line
	const [pageContent, setPageContent] = useState([]);
	const [pageBlocks, setPageBlocks] = useState([]);
	const [pageTitle, setPageTitle] = useState("</>");
	
	
	const pageSlug = props.slug ? props.slug : "home";
	
	let buffer = [];
	
	const fetchPage = async () => {
		
		setLoading(true);
		 
		const pageUrl = new URL(IMC.content+WPAPI.pages+pageSlug);
		
		const data = await fetch(pageUrl);
		
		const pageObj = await data.json();
		
		
		if (!props.pageTitle){
			setPageTitle(parse(pageObj[0].title.rendered));
		} else {
			setPageTitle(parse(props.pageTitle));
		}
		
		console.log(pageTitle);
		
		if (pageObj[0].has_blocks === true && pageObj[0].blocks.length){
			
			pageObj[0].blocks.map( (block,idx) => {
				const renderedBlock = <BlockParser block={block} key={`block-${idx}`} />;
				buffer.push( renderedBlock );
				return true;
			} );
			
			setPageBlocks(buffer);
			setLoading(false);
			
		} else {
			if (pageObj[0].hasOwnProperty('content')){
				// const pageContent = pageObj[0].content.rendered;
				setPageContent(pageObj[0].content.rendered);
				setLoading(false);
			}
		}
		
		scrollUp();
		
	}
	
	useEffect( () => {
		
		fetchPage();
		
		window.onscroll = () => {
			
			let theTimeline = document.querySelector('.timeline-items');
			
			if (theTimeline){
				
				timelineScroll( theTimeline, window.pageYOffset);
				
			}
			
			// console.log(window.pageYOffset);
		}
		// eslint-disable-next-line
	},[] );

	
	return (
		<div className={`page ${pageSlug}`}>
		<Helmet>
			<title>Imago Mundi Collection - {pageTitle}</title>
		</Helmet>
			{
				isLoading === true ? ( <LoadingIcon />) : (
					<>
					{
						props.withTitle ? (
						<header className="page-header">
							<div className="page-header-content">
								<Breadcrumbs parents={[]} curPage={pageSlug} curPageTitle={pageTitle} />
								<h1>{pageTitle}</h1>
							</div>
						</header>
						) : (null)
					}
					<div className="page-content">
						{pageBlocks}
					</div>
					</>
				)
			}
		</div>
	);
	
}

export default PageWithBlocks;
