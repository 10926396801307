const CONTINENTS = {
	"AS" : "Asia",
	"EU" : "Europe",
	"AF" : "Africa",
	"NA" : "North America",
	"OC" : "Oceania",
	"SA" : "South America",
	"AN" : "Antarctica"
};

export default CONTINENTS;
