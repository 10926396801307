import React, {useState, useEffect} from 'react';
import parse from 'html-react-parser';
import {Helmet} from 'react-helmet';
import {scrollUp} from '../services/helpers.js';
import { IMC, WPAPI } from '../services/wpClient';
import Breadcrumbs from '../components/Breadcrumbs';
import LoadingIcon from '../components/LoadingIcon';


function StaticPage({match}){
	
	const [page, setData] = useState({title: "", content: ""});
	const [isLoading,setLoading] = useState(true);
	
	useEffect(() => {
		
		const fetchPage = async () => {
			
			try {
			
				setLoading(true);
				
				const pageUrl = new URL(IMC.content+WPAPI.pages+match.params.pageSlug);
				
				const response = await fetch(pageUrl);
				
				const data = await response.json();
				
				const pageData = data[0];
				
				setData({ content: pageData.content.rendered, title: pageData.title.rendered });
				
			} catch(error){
				
				console.log(error);
				
			} finally {
				
				setLoading(false);
				scrollUp();
				
			}
			
		};
		
		fetchPage();
		
	}, [match.params.pageSlug]);
	
	
	return (
		<>
		{ page.title ? (
			<>
				<Helmet>
					<title>Imago Mundi Collection - {parse(page.title)}</title>
				</Helmet>
				<div className="page static" data-page-slug={match.params.pageSlug}>
					<header className="page-header">
						<div className="page-header-content">
							<Breadcrumbs parents={[]} curPage={match.params.pageSlug} curPageTitle={parse(page.title)} />
							<h1>{parse(page.title)}</h1>
						</div>
					</header>
					<div className="page-content">{parse(page.content)}</div>
				</div>
			</>
		) : (
			isLoading ? <LoadingIcon />
			: (
				<div className="page" data-page-slug={match.params.pageSlug}>
					<h1>404</h1>
					<div className="page-content">
						<p>The page you requested could not be found.</p>
					</div>
				</div>
			)
		)}
		</>
	);
	
}

export default StaticPage
