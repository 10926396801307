import React from 'react';
import {BrowserRouter, Switch, Route} from 'react-router-dom';
import Header from './components/header';
import Footer from './components/footer';
import CookieBar from './components/CookieBar';
import NewsletterBox from './components/newsletterBox';
import PageWithBlocks from './components/PageWithBlocks';
import PageHome from './components/PageHome';
// import PageMapsProjects from './components/PageMapsProjects';
import StaticPage from './components/staticPage';
import ArtworksArchive from './components/artworksArchive';
import Artwork from './components/artwork';
import CataloguesArchive from './components/cataloguesArchive';
import Catalogue from './components/catalogue';
import SearchPage from './components/SearchPage';
import TagManager from 'react-gtm-module'
// import WPAPI from './services/wpClient';


// function shuffleArray(array) {
//   for (let i = array.length - 1; i > 0; i--) {
//       const j = Math.floor(Math.random() * (i + 1));
//       [array[i], array[j]] = [array[j], array[i]];
//   }
//           // <Header id="main-header" />
// }


const App = () => {
  
  const tagManagerArgs = {
    // gtmId: 'GTM-MFZ9QFH' // old ID
    gtmId: 'GTM-WJZN6X9P'// new ID, 2023-09-14
  }
  
  TagManager.initialize(tagManagerArgs);
    
  return (
    <div className="App">
      <BrowserRouter>
          <Route render={ (routeprops) => {
            const headerClass = routeprops.location.pathname.indexOf('/search') > -1 ? "dark" : "";
            return(
              <Header id="main-header" className={headerClass} />
            )
          }} />
        <Switch>
          <Route path="/" exact component={PageHome} />
          <Route path="/terraforms" exact component={ (props) => <PageWithBlocks slug="terraforms" withTitle={true} pageTitle="TerraForms" />} />
          <Route path="/search/:query?" exact component={SearchPage} />
          <Route path="/artworks/" exact component={ArtworksArchive} />
          <Route path="/artworks/:slug" component={Artwork} />
          <Route path="/catalogues/" exact component={CataloguesArchive} />
          <Route path="/catalogues/:slug" component={Catalogue} />
          <Route path="/the-collection" exact component={ (props) => <PageWithBlocks slug="the-collection" withTitle={true} pageTitle="The Collection" />} />
          <Route path="/:pageSlug" exact component={StaticPage} />
        </Switch>
        <Route path="/" component={(props) => <NewsletterBox {...props} dataKey={window.location.pathname} /> } />
        <Footer id="main-footer" />
        <CookieBar />
      </BrowserRouter>
    </div>
  )
}

export default App;
