import React, {useState, useEffect, useCallback} from 'react';
import {Helmet} from 'react-helmet';
import parse from 'html-react-parser';
import { Link, useHistory } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import * as qs from 'query-string';
import { IMC, WPAPI } from '../services/wpClient';
import COUNTRIES from '../services/countries';
import {scrollUp} from '../services/helpers.js';
import Breadcrumbs from '../components/Breadcrumbs';
import SearchForm from '../components/SearchForm';
import LoadingIcon from '../components/LoadingIcon';
import "./catalogues.scss";


const maxPosts = 24;


function CataloguesArchive({...props}){
	
	let history = useHistory();
	
	const urlParams = qs.parse(props.location.search);


	// console.log(urlParams);
	
	const [catalogues,setCatalogues] = useState([]);
	const [total,setTotal] = useState([]);
	const [returnedIDs, setReturnedIDs] = useState([]);
	const [newResults, setNewResults] = useState([]);
	const [page,setPage] = useState(0);
	const [isLoading,setLoading] = useState(true);
	// const [filtersOpen, setFiltersOpen] = useState(true);
	
	// const [filterObj,setFilterObj] = useState({
	// 	continents: [],
	// 	regions: [],
	// });
	// eslint-disable-next-line
	const [search,setSearch] = useState({
		s: urlParams.s ? urlParams.s : ""
	});
	
	const [searchParams, setSearchParams] = useState({
		s: urlParams.s ? urlParams.s : "",
		continents: urlParams.continents ? urlParams.continents : "",
	});
	
	const [searchQuery, setSearchQuery] = useState({
		...searchParams,
		// page: page,
		// maxposts: maxPosts,
	});
	
	const handleSearchSubmit = useCallback(e => {
		
		//e.preventDefault();

		setPage((page) => 0);
		setCatalogues((catalogues) => []);
		setReturnedIDs((returnedIDs) => []);
		
		let searchVal = e.search ? e.search : "";
		
		setSearchParams({...searchParams, s: searchVal});
		
		const newSearchQuery = {
			...searchParams,
			page: page,
			maxposts: maxPosts,
		};
		
		console.log("search changed!",searchParams);
		
		setSearchQuery(newSearchQuery);
		
		// console.log("searchParams:",searchParams,search);
		
		if (searchVal){
			
			// console.log(searchParams);
			
			// remove empty params
			const cleanParams = Object.keys(searchParams)
				.filter((k) => searchParams[k])
				.reduce((a,k) => ({...a, [k]: searchParams[k] }), {} );
			
			const flatParams = qs.stringify( cleanParams, {arrayFormat: 'comma'});
			
			
			history.push('/catalogues?'+flatParams);
			
		}
		
		
	},[history, page, searchParams]);
	
	const handleSearchChange = useCallback(e => {
		
		e.preventDefault();

		setPage((page) => 0);
		setCatalogues((catalogues) => []);
		setReturnedIDs((returnedIDs) => []);
		
		let searchVal = e.target.value ? e.target.value : "";
		
		setSearchParams({...searchParams, s: searchVal, page: page, maxposts: maxPosts});
		
		setSearchQuery(searchParams);
		
	},[page, searchParams]);
	
	
	const handleFiltersChange = useCallback(
		(changedSearchParams) => {
			
			setPage((page) => 0);
			setCatalogues((catalogues) => []);
			setReturnedIDs((returnedIDs) => []);
			
			const newSearchParams = {
				...searchParams,
				...changedSearchParams,
				page: page,
				maxposts: maxPosts,
			};
			
			setSearchParams({...searchParams, ...changedSearchParams});
			
			setSearchQuery(newSearchParams);
		},
		[page, searchParams],
	);


	useEffect(() => {
		
		const fetchCatalogues = async () => {
			
			if (page === 0){ scrollUp() }		
			
			try {
	
				setLoading(true);
				
				const cataloguesURL = new URL(IMC.content+WPAPI.catalogues);			
				
				const urlParams = {
					...searchParams,
					maxposts: maxPosts,
					not_in: returnedIDs,
					rand: 0,
				};
	
				// console.log("urlParams:",urlParams);
				
				cataloguesURL.search = new URLSearchParams(urlParams);
				
				const response = await fetch(cataloguesURL);
				const data = await response.json();
				const items = data.items;
				const total = data.total;
				
				const cataloguesIDs = items.map((catalogue)=>{
					return catalogue.id;
				});
	
				setNewResults(items.length);
				
				setReturnedIDs((returnedIDs) => [...returnedIDs, ...cataloguesIDs]);
				
				setCatalogues((catalogues) => [...catalogues, ...items]);
				
				setTotal(total);
				
	
			} catch (error) {
	
				console.log('error loading catalogues:', error);
	
			} finally {
	
				setLoading(false);
	
			}
			
			
		}
		
		fetchCatalogues();
		// eslint-disable-next-line
	},[page, searchQuery, searchParams]);
	
	const loadMore = () => {
		setPage((page) => page + 1);
	};
	
	
	
	return(
		<>
		<Helmet>
			<title>Imago Mundi Collection - Catalogues</title>
		</Helmet>
		<div className="page archive catalogues-container">
			<header className="page-header">
					<div className="page-header-content">
						<Breadcrumbs parents={[]} curPageTitle={"Catalogues"} />
						<h1>Catalogues</h1>
					</div>
					<SearchForm placeholder="Search by country or title" onSearchSubmit={handleSearchSubmit} searchParams={searchParams} setSearchParams={setSearchParams} handleSearchChange={handleSearchChange} handleFiltersChange={handleFiltersChange} archiveType="catalogues" enabledFilters={['continents']} value={search.s} />
					<div className="results-count aligncenter">
					{
						(isLoading && catalogues.length === 0) ? <LoadingIcon />
						: [
							catalogues.length > 0 ?
								<p>showing <strong>{catalogues.length}</strong> of <strong>{total}</strong> Catalogues found</p>
								: <p>No Catalogues match your search criteria.</p>
						]
					}</div>
				</header>
			{ console.log(catalogues) }
			{
				catalogues.length > 0 ? (
					<>
						<ul className="archive-grid catalogues-grid">
						{catalogues.map((catalogue) => {
							const thumb = catalogue.thumb;
							const region = catalogue.region;
							const country = COUNTRIES.name[region];
							const artworksCount = catalogue.artworksCount;
							
							
							return (
							<li key={catalogue.id} id={catalogue.id} className="archive-grid-item catalogue-item">
								<Link to={`/catalogues/${catalogue.slug}/`}>
									<div className="grid-item-image-container">
										<img src={thumb} width={200} className="grid-item-image catalogue-image" alt={parse(catalogue.title)} />
									</div>
									<div className="grid-item-meta">
										<span className="title__xxs uppercase catalogue-country">{country}</span>
										<h3 className="title__m catalogue-title">{parse(catalogue.title)}</h3>
										<h4 className="title__xxs uppercase weight-400 grey catalogue-artworks-count">{artworksCount} artworks</h4>
									</div>
								</Link>
							</li>)
								
							}
							)
							
						}
						</ul>
						{ newResults >= maxPosts && (
								<div className="load-more">
									{ isLoading ?
										( <LoadingIcon /> ) :
										( <button onClick={loadMore} className="button cta">Show More</button>)
									}
								</div>
							)
							}
							</>
					) : (
						null
					)
					

			}
		</div>
		</>

	);
	
}


export default withRouter(CataloguesArchive);
