export function handleCF7Submit(e) {

	e.preventDefault();
	
	const formElement = e.target,
	{ action, method } = formElement,
	formMessages = formElement.querySelector('.form-messages'),
	body = new FormData(formElement);
	
	// reset field errors
	formElement.querySelectorAll('.error').forEach((el) => { el.classList.remove('error') });
	
	formMessages.innerHTML = "";
	
	
	const fetchFormFeedback = async () => {
		
		const fetchFormFeedback = await fetch(action, {method, body});
		
		const resp = await fetchFormFeedback.json();
		
		switch (resp.status) {
			case "validation_failed":
				
				formMessages.innerHTML = resp.message;
				
				if (resp.invalid_fields.length > 0){
				
					for (let i=0; i < resp.invalid_fields.length; i++) {
						
						const field_name = resp.invalid_fields[i].into.split(/[.]+/).pop();
						const invalid_field = formElement.querySelector(`[name="${field_name}"]`);
						
						if (invalid_field){ invalid_field.classList.add('error'); }
						
					}
				}
				break;
			case "acceptance_missing":
				formMessages.innerHTML = resp.message;
				formElement.querySelector(`[name="acceptance"]`).classList.add('error');
				break;
			case "mail_failed":
				
				break;
			case "mail_sent":
				
				formElement.reset();
				
				formMessages.innerHTML = resp.message;
				
				break;
			default:
				console.log('unexpected CF7 response: ',resp.status);
				break;
		}
		
	}
	
	fetchFormFeedback();

}
