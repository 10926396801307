import React from 'react';
import CookieConsent from "react-cookie-consent";

function CookieBar() {
	return (
		<CookieConsent
			location="bottom"
			buttonText="OK"
			cookieName="IMC-cookie-consent"
			buttonWrapperClasses="cookie-button"
			buttonClasses="cta wider"
			disableButtonStyles={true}
			/*
			*/
			style={{
				background: "var(--color-bg)",
				boxShadow: "0 0 20px rgba(0,0,0,0.3)",
				color: "var(--color-text)",
				fontSize: "var(--font-size-xs)",
				padding: "0 var(--u)",
				textAlign: "center",
				justifyContent: "center",
				alignItems: "center",
			}}
			contentStyle={{
				// color: "pink",
				flex: "0 1 auto",
				margin: "0.25em 0 0em 0",
			}}
			buttonStyle={{
				fontSize: "var(--font-size-xs)",
				margin: "15px",
				padding: "0.7em 2em 0.3em 2em",
			}}
			expires={150}
		>This website uses cookies to enhance the user experience. {" "}</CookieConsent>
	)
}

export default CookieBar
